.App {
  text-align: center;

  /* sticky footer part 1/2 */
  min-height: 100vh;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

/* sticky footer part 2/2 */
.view {
  flex: 1;
  margin-top: 56px;
}

a {
  text-decoration: none;
  color: #007bff;
}
