.page-title {
    color: #1340a0;
    margin: 1rem;
}

.form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.login-form {

    border-style: solid;
    border-width: 0.05rem;
    border-color: rgba(128, 128, 128, 0.767);
    border-radius: 5px;
    margin: 1rem;
    padding: 1rem;
}

@media screen and (min-width: 1024px) {
    .login-form {
        width: 40%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
    .login-form {
        width: 60%;
    }
}

@media screen and (max-width: 799px) {
    .login-form {
        width: 80%;
    }
}
